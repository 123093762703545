/*
 * this code loads for multidevs, dev and test env
 * cbi - check browser info
 */

export default function checkBrowserInfo() {
	const params = new URLSearchParams(window.location.search);

	if (params.has('check-browser-info')) {
		const cbiIcon = document.createElement('div');
		const cbiIconButton = document.createElement('button');
		const cbiIconClose = document.createElement('button');
		cbiIcon.classList.add('cbiIcon');
		cbiIconButton.innerHTML = 'Copy Borwser Info';
		cbiIconButton.classList.add('cbiIcon__button');
		cbiIconClose.innerHTML = 'x';
		cbiIconClose.classList.add('cbiIcon__close');

		cbiIcon.appendChild(cbiIconButton);
		cbiIcon.appendChild(cbiIconClose);

		document.body.appendChild(cbiIcon);

		cbiIcon.setAttribute(
			'style',
			'cursor:pointer;display:grid;grid-template-columns: 1fr auto;grid-template-rows: 1fr;z-index:9999999;position:fixed;bottom:10px;right:10px;'
		);
		cbiIconButton.setAttribute(
			'style',
			'display:inline-block;font-size:12px;text-transform:uppercase;background-color:white;border:none;border-top-left-radius:15px;border-bottom-left-radius:15px;cursor:pointer;padding:10px 20px;'
		);
		cbiIconClose.setAttribute(
			'style',
			'display:flex;justify-content:center;align-items:center;font-size:12px;padding-right:10px;padding-left:10px;text-transform:uppercase;border:none;background-color:black;color:white;border-top-right-radius:15px;cursor:pointer;border-bottom-right-radius:15px;'
		);

		function copyToClipBoard(text) {
			let textArea = document.createElement('textarea');
			let buttonText = cbiIconButton.innerHTML;
			cbiIcon.appendChild(textArea);
			textArea.style.opacity = 0;
			textArea.style.position = 'absolute';
			textArea.value = text;
			textArea.focus();
			textArea.select();
			try {
				var successful = document.execCommand('copy');
				var msg = successful ? 'successful' : 'unsuccessful';
				textArea.remove();
				cbiIconButton.innerHTML = 'COPIED!';
			} catch (err) {
				textArea.remove();
				cbiIconButton.innerHTML = 'Sorry.';
			}
			setTimeout(function () {
				cbiIconButton.innerHTML = buttonText;
			}, 1000);
		}

		function getBrowserData() {
			let browserAgent = navigator.userAgent;
			let browserName = navigator.appName;
			let browserVersion = '' + parseFloat(navigator.appVersion);
			let browserMajorVersion = parseInt(navigator.appVersion, 10);
			let Offset, OffsetVersion, ix;
			// For Chrome
			if ((OffsetVersion = browserAgent.indexOf('Chrome')) != -1) {
				browserName = 'Chrome';
				browserVersion = browserAgent.substring(OffsetVersion + 7);
			}

			// For Microsoft internet explorer
			else if ((OffsetVersion = browserAgent.indexOf('MSIE')) != -1) {
				browserName = 'Microsoft Internet Explorer';
				browserVersion = browserAgent.substring(OffsetVersion + 5);
			}

			// For Firefox
			else if ((OffsetVersion = browserAgent.indexOf('Firefox')) != -1) {
				browserName = 'Firefox';
			}

			// For Safari
			else if ((OffsetVersion = browserAgent.indexOf('Safari')) != -1) {
				browserName = 'Safari';
				browserVersion = browserAgent.substring(OffsetVersion + 7);
				if ((OffsetVersion = browserAgent.indexOf('Version')) != -1)
					browserVersion = browserAgent.substring(OffsetVersion + 8);
			}

			// For other browser "name/version" is at the end of userAgent
			else if (
				(Offset = browserAgent.lastIndexOf(' ') + 1) <
				(OffsetVersion = browserAgent.lastIndexOf('/'))
			) {
				browserName = browserAgent.substring(Offset, OffsetVersion);
				browserVersion = browserAgent.substring(OffsetVersion + 1);
				if (browserName.toLowerCase() == browserName.toUpperCase()) {
					browserName = navigator.appName;
				}
			}

			// Trimming the fullVersion string at
			// semicolon/space if present
			if ((ix = browserVersion.indexOf(';')) != -1)
				browserVersion = browserVersion.substring(0, ix);
			if ((ix = browserVersion.indexOf(' ')) != -1)
				browserVersion = browserVersion.substring(0, ix);

			browserMajorVersion = parseInt('' + browserVersion, 10);
			if (isNaN(browserMajorVersion)) {
				browserVersion = '' + parseFloat(navigator.appVersion);
				browserMajorVersion = parseInt(navigator.appVersion, 10);
			}

			copyToClipBoard(
				`Window size: ${window.innerWidth} x ${window.innerHeight}. Screen Size: ${screen.width} x ${screen.height}. My browser information: ${browserName}, ${browserVersion},${browserMajorVersion},${browserAgent}.`
			);
		}

		cbiIconButton.addEventListener('click', getBrowserData);
		cbiIconClose.addEventListener('click', function () {
			cbiIcon.remove();
		});
	}
}
